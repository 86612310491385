<template>
    <div>
        <h2>Impressum</h2>
        <p>Henri Ganster</p>
        <p>51702 Bergneustadt</p>
        <a href="mailto:flatbread1999@gmail.com">flatbread1999@gmail.com</a>
    </div>
</template>

<script>
    export default {
        name: "Impressum"
    }
</script>

<style scoped>

</style>
