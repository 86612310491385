<template>
    <div>
        <h2>Hallo! Ich bin Flatbread99!</h2>
        <p>Ich programmiere seit 2009 Java und habe im Jahr 2019 mein Abitur gemacht. Aktuell studiere ich Angewandte Mathematik und Informatik.</p>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style>

</style>
