<template>
    <div id="app">
        <Page />
    </div>
</template>

<script>
    import Page from "./components/Page";

    export default {
        name: "App",
        components: {
            Page
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        user-select: none;
    }
</style>
