<template>
    <div class="footer-clean">
        <footer>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-4 col-md-3 item">
                        <h3>Apps</h3>
                        <ul>
                            <li>
                                <router-link :to="{ name: 'acceptor-simulator' }">Automaten Simulator</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 col-md-3 item">
                        <h3>Über</h3>
                        <ul>
                            <li>
                                <router-link :to="{ name: 'about' }">Über mich</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'impressum' }">Impressum</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 item social">
                        <a href="">
                            <i class="icon ion-social-twitter" />
                        </a>
                        <a href="https://www.instagram.com/flatbread99">
                            <i class="icon ion-social-instagram" />
                        </a>
                        <p class="copyright">Flatbread99 © 2022</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    @import "../assets/fonts/ionicons.min.css";

    .footer-clean {
        padding: 50px 0;
        background-color: #fff;
        color: #4b4c4d;
    }

    .footer-clean h3 {
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
    }

    .footer-clean ul {
        padding: 0;
        list-style: none;
        line-height: 1.6;
        font-size: 14px;
        margin-bottom: 0;
    }

    .footer-clean ul a {
        color: inherit;
        text-decoration: none;
        opacity: 0.8;
    }

    .footer-clean ul a:hover {
        opacity: 1;
    }

    .footer-clean .item.social {
        text-align: right;
    }

    @media (max-width:767px) {
        .footer-clean .item {
            text-align: center;
            padding-bottom: 20px;
        }
    }

    @media (max-width: 768px) {
        .footer-clean .item.social {
            text-align: center;
        }
    }

    .footer-clean .item.social > a {
        font-size: 24px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-left: 10px;
        margin-top: 22px;
        color: inherit;
        opacity: 0.75;
    }

    .footer-clean .item.social > a:hover {
        opacity: 0.9;
    }

    @media (max-width:991px) {
        .footer-clean .item.social > a {
            margin-top: 40px;
        }
    }

    @media (max-width:767px) {
        .footer-clean .item.social > a {
            margin-top: 10px;
        }
    }

    .footer-clean .copyright {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 13px;
        opacity: 0.6;
    }
</style>
