<template>
    <div>
        <p>Momentan arbeite ich noch an meiner Website. Daher gibt es hier noch nicht sehr viel zu sehen!</p>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style>

</style>
