import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./pages/Home.vue";
import AcceptorSimulator from "./pages/AcceptorSimulator.vue";
import About from "./pages/About.vue";
import Impressum from "./pages/Impressum.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/acceptor-simulator",
        name: "acceptor-simulator",
        component: AcceptorSimulator
    },
    {
        path: "/about",
        name: "about",
        component: About
    },
    {
        path: "/impressum",
        name: "impressum",
        component: Impressum
    }
];

export default new VueRouter({
    mode: "history",
    routes
});