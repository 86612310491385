import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false;

firebase.initializeApp({
    apiKey: "AIzaSyBa_cEVkasSbEEMHIN7dl1gl3a57JZVw9E",
    authDomain: "flatbread99-homepage.firebaseapp.com",
    databaseURL: "https://flatbread99-homepage.firebaseio.com",
    projectId: "flatbread99-homepage",
    storageBucket: "flatbread99-homepage.appspot.com",
    messagingSenderId: "606352736539",
    appId: "1:606352736539:web:56d6a4eb95868c666d93be",
    measurementId: "G-TJLNHS3LCM"
});

new Vue({
    el: '#app',
    router,
    render: h => h(App),
});
