<template>
    <div>
        <h2>Automaten Simulator</h2>
        <p>
            Mit Hilfe des Automaten Simulator kannst du ganz einfach deinen Automat erstellen und testen. Werden alle
            Wörter akzeptiert, die es sollen? Finde es ganz leicht heraus!
        </p>
        <p>
            Aktuelle Version: <a :href="latestVersion" download>download</a>
        </p>
        <table class="table container">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Version</th>
                    <th scope="col">Link</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1.1.0</th>
                    <th><a :href="v110">download</a></th>
                </tr>
                <tr>
                    <th scope="row">1.0.0</th>
                    <th><a :href="v100">download</a></th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import firebase from "firebase";

    export default {
        name: "AcceptorSimulator",
        data() {
            return {
                latestVersion: "",
                v110: "",
                v100: ""
            }
        },
        async created() {
            //this.getLatestVersionUrl();
            this.latestVersion = await this.getVersion("");
            this.v110 = await this.getVersion("1.1.0");
            this.v100 = await this.getVersion("1.0.0");
        },
        methods: {
            async getLatestVersionUrl() {
                let ref = firebase.storage().ref("downloads/acceptor-simulator/acceptor-simulator.jar");
                this.latestVersion = await ref.getDownloadURL();
            },
            async getVersion(version) {
                if (version) {
                    version = "-" + version;
                }
                let path = "downloads/acceptor-simulator/acceptor-simulator" + version + ".jar";
                let ref = firebase.storage().ref(path);
                return await ref.getDownloadURL();
            }
        }
    }
</script>

<style scoped>
</style>
