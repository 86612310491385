<template>
    <div>
        <nav class="navbar navbar-light navbar-expand-md navigation-clean">
            <div class="container">
                <router-link class="navbar-brand" :to="home">Flatbread99</router-link>
                <button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse"
                     id="navcol-1">
                    <ul class="nav navbar-nav ml-auto">
                        <li class="nav-item" role="presentation">
                            <router-link class="nav-link" :to="acceptorSimulator">Automaten Simulator</router-link>
                        </li>
                        <li class="nav-item" role="presentation">
                            <router-link class="nav-link" :to="about">Über mich</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>

    export default {
        name: "Header",
        computed: {
            home() {
                return {
                    name: "home"
                }
            },
            acceptorSimulator() {
                return {
                    name: "acceptor-simulator"
                }
            },
            about() {
                return {
                    name: "about"
                }
            }
        }
    }
</script>

<style scoped>
    .navigation-clean {
        background: #fff;
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: #333;
        border-radius: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 0;
    }

    @media (min-width:768px) {
        .navigation-clean {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .navigation-clean .navbar-brand {
        font-weight: bold;
        color: inherit;
    }

    .navigation-clean .navbar-brand:hover {
        color: #222;
    }

    .navigation-clean.navbar-dark .navbar-brand:hover {
        color: #f0f0f0;
    }

    .navigation-clean .navbar-brand img {
        height: 100%;
        display: inline-block;
        margin-right: 10px;
        width: auto;
    }

    .navigation-clean .navbar-toggler {
        border-color: #ddd;
    }

    .navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
        background: none;
    }

    .navigation-clean.navbar-dark .navbar-toggler {
        border-color: #555;
    }

    .navigation-clean .navbar-toggler {
        color: #888;
    }

    .navigation-clean.navbar-dark .navbar-toggler {
        color: #eee;
    }

    .navigation-clean .navbar-collapse, .navigation-clean .form-inline {
        border-top-color: #ddd;
    }

    .navigation-clean.navbar-dark .navbar-collapse, .navigation-clean.navbar-dark .form-inline {
        border-top-color: #333;
    }

    .navigation-clean .navbar-nav > .active > a, .navigation-clean .navbar-nav > .show > a {
        background: none;
        box-shadow: none;
    }

    .navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
        color: #8f8f8f;
        box-shadow: none;
        background: none;
        pointer-events: none;
    }

    .navigation-clean.navbar .navbar-nav .nav-link {
        padding-left: 18px;
        padding-right: 18px;
    }

    .navigation-clean.navbar-light .navbar-nav .nav-link {
        color: #465765;
    }

    .navigation-clean.navbar-light .navbar-nav .nav-link:focus, .navigation-clean.navbar-light .navbar-nav .nav-link:hover {
        color: #37434d !important;
        background-color: transparent;
    }

    .navigation-clean .navbar-nav > li > .dropdown-menu {
        margin-top: -5px;
        box-shadow: none;
        background-color: #fff;
        border-radius: 2px;
    }

    @media (min-width:768px) {
        .navigation-clean .navbar-nav .show .dropdown-menu {
            box-shadow: 0 4px 8px rgba(0,0,0,.1);
        }
    }

    @media (max-width:767px) {
        .navigation-clean .navbar-nav .show .dropdown-menu .dropdown-item {
            color: #37434d;
            padding-top: .8rem;
            padding-bottom: .8rem;
            line-height: 1;
        }
    }

    .navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
        line-height: 2;
        color: #37434d;
    }

    .navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
        background: #eee;
        color: inherit;
    }

    .navigation-clean.navbar-dark {
        background-color: #1f2021;
        color: #fff;
    }

    .navigation-clean.navbar-dark .navbar-nav a.active, .navigation-clean.navbar-dark .navbar-nav a.active:focus, .navigation-clean.navbar-dark .navbar-nav a.active:hover {
        color: #8f8f8f;
        box-shadow: none;
        background: none;
        pointer-events: none;
    }

    .navigation-clean.navbar-dark .navbar-nav .nav-link {
        color: #dfe8ee;
    }

    .navigation-clean.navbar-dark .navbar-nav .nav-link:focus, .navigation-clean.navbar-dark .navbar-nav .nav-link:hover {
        color: #fff;
        background-color: transparent;
    }

    .navigation-clean.navbar-dark .navbar-nav > li > .dropdown-menu {
        background-color: #1f2021;
    }

    .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean.navbar-dark .dropdown-menu .dropdown-item {
        color: #f2f5f8;
    }

    .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:focus, .navigation-clean.navbar-dark .dropdown-menu .dropdown-item:hover {
        background: #363739;
    }

    @media (max-width:767px) {
        .navigation-clean.navbar-dark .navbar-nav .show .dropdown-menu .dropdown-item {
            color: #fff;
        }
    }
</style>
